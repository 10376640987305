import { contentStyle } from "../fuctions";
const titleStyle = contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 50px;
line-height: 61px;
`;

const ShowCoachTitle = () => {
    return <p
    style={titleStyle}
    >
        Choose your Own Aspect
      </p>;
  };
  
  export default ShowCoachTitle;
  