import Wrapper from '../../assets/wrappers/QuestionWrap';
import QuestionSectionTitle from '../../components/QuestionSectionTitle';
import Questions from '../../components/Questions';
import { contentStyle } from '../../fuctions';

const innerStyle = contentStyle`
background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(170,170,170,0.6558998599439776) 100px, rgba(170,170,170,0.66) 300px, rgba(255,255,255,1) 100%);

`;

const Question = () => {
  return (
    <Wrapper>
      <div style={innerStyle} className="max-w-7xl px-4 mx-auto sm:px-6    ">
        <div className="relative pt-10 pb-10 md:pb-16 flex flex-col gap-10">

          <QuestionSectionTitle></QuestionSectionTitle>
          <Questions></Questions>
        </div>
      </div>
    </Wrapper>
  );
};

export default Question;


