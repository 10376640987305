import { styled } from "styled-components";
import { Button } from "antd";
import { contentStyle } from "../fuctions";
const gradient = contentStyle`
background-image: radial-gradient(circle at center, #EBFAED 0%, rgba(255, 255, 255, 0) 100%);
`;

const startButtonStyle = contentStyle`
width: 200px;
height: 40px;
float: left;

background: linear-gradient(90deg, #14A800 0%, #00D715 100%);
border-radius: 10px;
background-color: lightgreen;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;

color: #FFFFFF;
`;

const IntroTitle = styled.p`
// width: 1112px;
// height: 201px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 67px;

color: #000000;

text-align: left;text-align: left;

margin-bottom: 5%;
`;

const tipStyle =contentStyle`
font-family: 'Inter', sans-serif;
font-size: 20px;
line-height: 30px;
text-align: left;
vertical-align: top;

display: flex;
margin-bottom: 2%;
`;
const bulletStyle=contentStyle`
width: 25px;
height: 25px;

background: #000000;
border-radius: 0px 20px;
`;


const Hello = () => {
  // console.log(startButtonStyle);
    return <div 
    className="md:[zoom:1] [zoom:0.7]"
    style={{
      width:"60%",
      // padding:"10%",
      ...gradient,
    }}>
      <IntroTitle>Join the Large platform for connecting coaches and Trainers for different purposes</IntroTitle>
      <div>
      <div style={tipStyle}><div style={bulletStyle}></div>&nbsp;&nbsp;Take customizable 1-on-1 lessons trusted by millions of users</div>
      <div style={tipStyle}><div style={bulletStyle}></div>&nbsp;&nbsp;Learn from certified coaches that fit your budget and schedule</div>
      <div style={tipStyle}><div style={bulletStyle}></div>&nbsp;&nbsp;Become a coach and then provide training to the other people</div>
    </div>
      <Button style={startButtonStyle}>Start now</Button>
      </div>;
  };
  
  export default Hello;
  