import Wrapper from '../../assets/wrappers/Intro';

import ProfileView from '../../components/ProfileView';
import StateView from '../../components/StateView';
import LessonsView from '../../components/LessonsView';
import Reviews from '../../components/Reviews';
import Similar from '../../components/Similar';
import Availability from '../../components/Availability';

import ContactTeacher from './ContactTeacher';
const ProfileOverView = () => {
  return (
    <Wrapper>
      <div className="max-w-7xl px-4 mx-auto sm:px-6    ">
        <div className="relative pt-32 pb-10 md:pt-40 md:pb-16 flex flex-col justify-start gap-10">
          <ProfileView></ProfileView>
          <ContactTeacher></ContactTeacher>
          {/* <StateView></StateView>
          <LessonsView></LessonsView>
          <Availability></Availability>
          <Reviews></Reviews>
          <Similar></Similar> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default ProfileOverView;
