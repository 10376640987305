import { contentStyle } from "../fuctions";


const authorStyle = contentStyle`
width: 296px;
height: 30px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
color: #000000;
`;
// alert(authorStyle);
const Author = () => {
    return <div style={authorStyle}>
© 2023 italki HK Limited.      </div>;
  };
  
  export default Author;
  