import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { contentStyle } from '../fuctions';

import avatar from "./../assets/images/avatar.png"





const groupCardList = [
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    },
    {
        image: "background",
        time: "17: 00  -  20: 00  Saturday,  June, 2023",
        title: "Training people on how to control your emotions",
        description: "Group class with John Doe",
        avatar: avatar,
        price: {
            unit: "USD",
            value: 20
        },
        stateDescription: "Be the first!"
    }
]

const timeStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 25px;

color: #14A800;
`;
const titleStyle = contentStyle`
text-align: left;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;

color: #000000;

`;


const descriptionStyle = contentStyle`
text-align: left;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;

color: #777777;
`;
const priceStateDescriptionWrapStyle = contentStyle`
display: flex;
justify-content: space-between;
`;
const avatarStyle = contentStyle`
// background: url(.jpg), #D9D9D9;
`;
const priceStyle = contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

color: #000000;
`;
const stateDescriptionStyle = contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #14A800;
`;


// sm:w-[320px] sm:h-[500px]
// md:w-[520px] md:h-[750px] 
// lg:w-[320px] lg:h-[500px] 
// xl:w-[520px] xl:h-[750px] 
const ShowGroupCoachCards = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    return <div className="
    w-full 
    inline-flex 
    items-center 
    flex-wrap 
    justify-center
    gap-[30px] 
    p-[5%]
    ">
        {/* <video className="bg-green-500"></video> */}
        {groupCardList.map((i, index) => {
            return <>
                <div key={index} className="
                p-3
                w-[320px]
                [bg-[#97f09b]]
                [box-shadow:0px_0px_10px_5px_rgba(_200,_200,_200,_10)]
                rounded-[10px]
                ">
                    <div>
                        <div class="grid min-h-[140px] w-full place-items-center 
        [overflow-x-scroll]
         rounded-lg p-0 lg:overflow-visible">
                            <video src={"https://www.material-tailwind.com/demo.mp4"} className="h-full w-full rounded-lg" controls='PlayPause'>
                                {/* <source src="https://www.material-tailwind.com/demo.mp4" type="video/mp4" />Your browser does not support the video tag. */}
                            </video>
                        </div>
                        <div style={timeStyle}>{i.time}</div>
                    </div>
                    <div style={titleStyle}>{i.title}</div>
                    <div style={descriptionStyle}>{i.description}</div>
                    <div>
                        <img style={avatarStyle} src={i.avatar}></img>

                        <div style={priceStateDescriptionWrapStyle}>
                            <div style={priceStyle}>{i.price.unit +" "+ i.price.value}</div>
                            <div style={stateDescriptionStyle}>🎃{i.stateDescription}</div>
                        </div>
                    </div>
                </div>
            </>;
        })}

    </div>;
};

export default ShowGroupCoachCards;
