import styled from 'styled-components';

const Wrapper = styled.div`
    box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.1);
    margin-top: 10vh;
    // margin-left: 10%;
    margin-bottom: 10vh;
    width: 80%;
    display: inline-flex;
    // align-items: center;
`;

export default Wrapper;
