import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MobileMenu from '../components/mobile-menu';
import { useMediaQuery } from 'react-responsive';
import { Logo } from '../components';
import { Button } from 'antd';


const elseTabClass = "py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300";
const currentTabClass = "py-4 px-2 text-green-500 border-b-4 border-green-500 font-semibold"

export default function Header() {

  const [currentPath, setPath] = React.useState(window.location.pathname.split('/')[1]);
  // alert(window.location.href)
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  return (
    <header className="absolute z-30 w-full">
      <div className="max-w-7xl px-4 mx-auto sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="mr-4 shrink-0">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              {/* <svg className="w-8 h-8 text-purple-600 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.952 14.751a260.51 260.51 0 00-4.359-4.407C23.932 6.734 20.16 3.182 16.171 0c1.634.017 3.21.28 4.692.751 3.487 3.114 6.846 6.398 10.163 9.737.493 1.346.811 2.776.926 4.262zm-1.388 7.883c-2.496-2.597-5.051-5.12-7.737-7.471-3.706-3.246-10.693-9.81-15.736-7.418-4.552 2.158-4.717 10.543-4.96 16.238A15.926 15.926 0 010 16C0 9.799 3.528 4.421 8.686 1.766c1.82.593 3.593 1.675 5.038 2.587 6.569 4.14 12.29 9.71 17.792 15.57-.237.94-.557 1.846-.952 2.711zm-4.505 5.81a56.161 56.161 0 00-1.007-.823c-2.574-2.054-6.087-4.805-9.394-4.044-3.022.695-4.264 4.267-4.97 7.52a15.945 15.945 0 01-3.665-1.85c.366-3.242.89-6.675 2.405-9.364 2.315-4.107 6.287-3.072 9.613-1.132 3.36 1.96 6.417 4.572 9.313 7.417a16.097 16.097 0 01-2.295 2.275z" />
              </svg> */}
              <Logo />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            <div className="flex justify-between w-[100%]">
              <div className="flex space-x-7 justify-stretch">
                {/* <!-- Primary Navbar items --> */}
                <div className="flex items-center space-x-1">
                  <a href="/landing" className={currentPath == "landing" ? currentTabClass : elseTabClass}>Welcome</a>
                  <a href="/find" className={currentPath == "find" ? currentTabClass : elseTabClass}>Find a Coach</a>
                  <a href="/groupClass" className={currentPath == "groupClass" ? currentTabClass : elseTabClass}>Group Class</a>
                  <a href="" className="hidden lg:block py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300">Be come a teacher</a>
                  <a href="/profile" className="hidden lg:block py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300">Profile(...)</a>
                  <a href="/room" className="hidden lg:block py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300">Room(...)</a>

                  {/* <a href="" class="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300">More</a> */}
                  <button className="
                  group
                  rounded-[50%]
                  lg:hidden 
                  py-4 px-2 text-gray-500 font-semibold
                   [box-shadow:0px_1px_2px_1px_rgb(39_198_79_/_20%)]
                  hover:transition duration-300  border-green-300 rounded-[10px]
                  ">More
                    <div className='hidden group-hover:block absolute
                       
                    '>
                      <div><br />
                      </div>
                      <div className="py-1 px-1
                      // [box-shadow:0px_0px_8px_0px]
                       flex inline-flex flex-col 
                       bg-white
                      ">
                        <a href="" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300">Be come a teacher</a>
                        <a href="/profile" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300">Profile(...)</a>
                        <a href="/room" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 hover:border-b-4 border-green-300">Room(...)</a>
                        
                        <div><br />
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              {/* <!-- Secondary Navbar items --> */}
              <div className="hidden md:flex items-center space-x-3 ">
                <a href="/signIn" className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300">Log In</a>
                <a href="/signUp" className="py-2 px-2 font-medium text-white bg-green-500 rounded hover:bg-green-400 transition duration-300">Sign Up</a>
              </div>
            </div>
          </nav>
          {/* Mobile navigation */}
          <MobileMenu />

        </div>
      </div>
    </header>
  )
};

