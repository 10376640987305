import logoImage from "./../assets/images/logo.png";
import videoCall from "./../assets/images/videoCall.svg"
const VideoCall = () => {
  return <div>
    <img src={videoCall} ></img>
    
    </div>;
};

export default VideoCall;
