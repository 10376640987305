
import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';

import FindIntro from './Content/FindIntro';
import ShowCoach from './Content/ShowCoach';
import Offer from './Content/Offer';
import Feedback from "./Content/Feedback";
import Question from './Content/Question';
import UseDevice from './Content/UseDevice';
import QuickAccess from './Content/QuickAccess';
import { styled } from 'styled-components';
import { contentStyle } from '../fuctions';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import RoomCall from './Content/RoomCall';
import OtherLessons from './Content/OtherLessons';


const { Header, Content, Footer } = Layout;
const RoomContent = () => {
    const user = false;
    return <Content className="">
        <RoomCall></RoomCall>
        <OtherLessons></OtherLessons>
    </Content>;    
};

export default RoomContent;
