import React from "react";
import { aspectList } from "./../data/constants";
import { Button } from "antd";
import { contentStyle } from "../fuctions";
import { Select, Space } from 'antd';

const selectedStyle = contentStyle`
padding: 10px;
// width: 100px;
// height: 60px;
// left: 130px;
// top: 1053px;

background: #14A800;
border-radius: 50px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
// font-size: 14px;
// line-height: 48px;

color: #FFFFFF;

`;
// alert(JSON.stringify(selectedStyle));
const unSelectedStyle = contentStyle`
padding: 10px;

box-sizing: border-box;

// width: 100px;
// height: 60px;

background: #FFFFFF;
border: 1px solid #14A800;
border-radius: 50px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
// font-size: 14px;
// line-height: 42px;

color: #000000;
`;


// const optionStyle=contentStyle`  
// cursor: pointer;
// width: 300px;
// min-height: 60px;
// max-height: 300px;
// border-radius: 15px;
// background-color: rgb(250, 250, 250);
// box-shadow: 2px 4px 8px #c5c5c5;
// transition: all 300ms;
// `;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const AspectTypeList = (props) => {
  return <div>
    {aspectList.map((i, index) => {
      return <span key = {index}>
<Select
className={`select-${index}`}

placeholder={i.type}
      // defaultValue="Fitness"
      style={{paddingLeft: 10}}
      onChange={handleChange}
      // options={[
      //   { value: 'jack', label: 'Jack' },
      //   { value: 'lucy', label: 'Lucy' },
      //   { value: 'Yiminghe', label: 'yiminghe' },
      //   { value: 'disabled', label: 'Disabled', disabled: true },
      // ]}
      options = {i.value.map(item=>{return {value:item, label:item}})}
    />
        {/* <Button
          className="group"
          key={i}
          style={{
            alignItems: "center",
            marginLeft: "1%",
            ...(i == props.currentAspect ? selectedStyle : unSelectedStyle)
          }}

          onClick={() => { props.selectAspect(i) }}

        >
          <div>
            <Button style={optionStyle}>
              {i}
            </Button>
          </div>
        </Button> */}
      </span>;
    })}
  </div>;
};

export default AspectTypeList;
