import Wrapper from '../../assets/wrappers/LandingPage';

import  Header  from '../../Layouts/Header';
import FindContent from '../../Layouts/FindContent';
import Footer from '../../Layouts/Footer';


const Find = () => {
  return (
    <Wrapper>
        <FindContent></FindContent>
        <Footer></Footer>
    </Wrapper>
  );
};

export default Find;
