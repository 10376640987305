
import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';
import Author from '../components/Author';
import Extern from '../components/Extern';

const { Footer } = Layout;

const AFooter = () => {
  return (<Footer style={{
    // backgroundColor:"gray",
    display:'flex',
    justifyContent: "space-between",
    alignItems:"center",
    padding: 10
    // height:10
  }}>   
     <Author></Author>
     <Extern></Extern>

     </Footer>
  );
};

export default AFooter;
