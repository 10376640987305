import { contentStyle } from "../fuctions";
import logoImage from "./../assets/images/logo.png";

const cardStyle=contentStyle`
background: #FFFFFF;
box-shadow: 0px 4px 20px 5px rgba(190, 190, 190, 0.25);
border-radius: 20px;
`
const titleStyle=contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 20px;
// line-height: 44px;
/* identical to box height */


color: #000000;

`
const descriptionStyle=contentStyle`
text-align: justify;
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
// font-size: 25px;
// line-height: 32px;

color: #000000;

`
const Summary = () => {
    return <div className="p-5" style={cardStyle}>
        <div style={titleStyle}>
            Summary
        </div>
        <div style={descriptionStyle}>
            This session of today we will be talking about the fitness and welness which is the first topic of this week and it is very interesting.
        </div>
    </div>;
};

export default Summary;
