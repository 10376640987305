import React from 'react';
import { useAppContext } from '../context/appContext';
import { Select, Space } from 'antd';
import { contentStyle } from '../fuctions';

const setStyle = contentStyle`
padding-top:100px;
display: flex;
gap: 10px;
flex-direction: column;
`;

const selectStyle = contentStyle`
box-sizing: border-box;

// position: absolute;
width:100%;
max-width: 350px;
// height: 60px;
// left: 1451px;
// top: 6082px;

background: #FFFFFF;
border: 1px solid #CDCDCD;
border-radius: 10px;
`;

const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

const LSet = () => {
  return <>
    <div style={setStyle}>
    <Select
      defaultValue="English"
      style={selectStyle}
      size='large'
      onChange={handleChange}
      options={[
        // { value: 'Language', label: 'Language' },
        { value: 'English', label: 'English' },
        { value: 'Russian', label: 'Russian' },
        { value: 'Chinese', label: 'Chinese', disabled: false },
      ]}
    />
    <Select
      defaultValue="USD"
      size='large'
      style={selectStyle}
      onChange={handleChange}
      options={[
        { value: 'USD', label: 'USD' },
        { value: 'EUR', label: 'EUR' },
        { value: 'GBP', label: 'GBP' },
        { value: 'JPY', label: 'JPY', disabled: false },
        { value: 'CHF', label: 'CHF' },
        { value: 'CAD', label: 'CAD' },
        { value: 'AUD', label: 'AUD' },
        { value: 'NZD', label: 'NZD' },
        { value: 'CNY', label: 'CNY' },

      ]}
    />
    </div>
  </>;
};

export default LSet;
