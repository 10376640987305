import React from "react";
import Wrapper from "../assets/wrappers/StepsWrap";
import StepLine from "./StepLine";
import StepSetting from "./StepSetting";

const Steps = () => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const goToStep =()=>{
        setCurrentStep(currentStep+1);
    }

    return (
        <Wrapper>
            <StepLine currentStep={currentStep}></StepLine>
            <StepSetting goToStep = {goToStep}></StepSetting>
        </Wrapper>
    )
  };
  
  export default Steps;
  