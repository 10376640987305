import React from 'react';
import { Link } from 'react-router-dom';
import main from '../assets/images/main.png';
import Wrapper from '../assets/wrappers/AuthBoardWrap';
import { CaretRightOutlined } from "@ant-design/icons"
import { contentStyle } from '../fuctions';

const panelStyle=contentStyle`
background: rgba(255, 255, 255, 0.28);
display: flex;
flex-flow: row nowrap;
place-content: center;
height: 65vh;
flex-direction: row;
flex-wrap: wrap;

`

const titleStyle = contentStyle`
text-align:left;
gap: 10px;
display: flex;
font-family: Outfit;
font-style: normal;
font-weight: 500;
// font-size: 50px;
line-height: 63px;
color: rgb(255, 255, 255);
flex-wrap: wrap;
align-items: center;
`;

const descriptionStyle=contentStyle`
text-align:left;
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 32px;

color: #FFFFFF;`;

const effectStyle1=contentStyle`
position: absolute;
width: 422px;
height: 401px;
margin:10px;

background: radial-gradient(50% 50% at 50% 50%, rgba(25, 166, 6, 0.63) 0%, rgba(97, 255, 71, 0.09) 100%);
// z-index:0;
`;
const effectStyle2=contentStyle`
position: absolute;
width: 549px;
height: 692px;

background: radial-gradient(50% 50% at 50% 50%, rgba(120, 140, 255, 0.47) 0%, rgba(255, 71, 71, 0) 100%);
`;


const AuthBoard = () => {
    return (
        <Wrapper>
            <div style={effectStyle1}/>
            <div style={effectStyle2}/>
            <div>
                {/* <Link style={{zIndex:3000}} to="/landing">🥑LandingPage🥑</Link> */}
                <div style={{
                    // width:"20%",
                    padding: "10%",
                    
                    // margin:"10%"
                    // height: "65vh",
                    // background: "rgba(255, 255, 255, 0.00)"
                }}>
                                    {/* <Link style={{zIndex:3000}} to="/landing">🥑LandingPage🥑</Link> */}

                    <div
                        style={panelStyle}
                    >
                        <CaretRightOutlined style={{
                            width: "25%",
                            height: 50, 
                            fontSize: 50, 
                            color: "white",
                            textAlign: "right",
                            zIndex:100
                        }} />
                        <span style={{
                            width:"60%",
                            zIndex:100
                        }}>
                        <div className="md:text-2xl
                      text-left
gap-[10px] flex font-[Outfit] not-italic font-medium leading-[63px] text-[rgb(255,_255,_255)] flex-wrap items-center
                        " style={{titleStyle}}>
                               Digital Platform for distance
                               <p style={{color:"black"}}>Learning</p> and <p style={{color:"black"}}>Training</p>
                            </div>
                        <div style={descriptionStyle}>You will learn many things to improve your skills and it is very important.</div>
                        </span>
                        <span
                        style={{
                            width:"15%"
                        }}
                        ></span>
                        
                        <br></br>
                        
                    </div>
                   
                </div>
            </div>
        </Wrapper>);
};

export default AuthBoard;
