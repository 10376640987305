
import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';

import FindIntro from './Content/FindIntro';
import ShowCoach from './Content/ShowCoach';
import Offer from './Content/Offer';
import Feedback from "./Content/Feedback";
import Question from './Content/Question';
import UseDevice from './Content/UseDevice';
import QuickAccess from './Content/QuickAccess';
import { styled } from 'styled-components';
import { contentStyle } from '../fuctions';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ProfileOverView from './Content/ProfileOverView';



const { Header, Content, Footer } = Layout;
const ProfileContent = () => {
    const user = false;
    return <Content className="flex justify-center">
        <ProfileOverView></ProfileOverView>
    </Content>;
};

export default ProfileContent;
