import React, { useState, useContext } from 'react';
import { Button, Divider, Drawer, Radio, Space, Steps } from 'antd';
import { CloseOutlined, LeftOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { contentStyle } from '../../fuctions';

import { useAppContext } from '../../context/appContext';


import startAvatar from "./../../assets/images/startAvatar.png"
import Schedule from '../../components/Schedule';
import Payment from "../../components/Payment";
const closeButtonStyle = contentStyle`
// box-sizing: border-box;
height: 45px;
background: #FFffff;
// border: 1px solid rgb(20, 168, 0);
border-radius: 50%;
display: flex;
align-items: center;
color: #000000;
`

const sectionTitleStyle = contentStyle`
text-align:center;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
// line-height: 42px;

color: #000000;
`
const ruleStyle = contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
// font-size: 25px;
// line-height: 30px;
text-align: center;

color: #000000;
`

const setTimeStyle = contentStyle`
text-align: center;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 35px;
line-height: 42px;

color: #000000;

`
const buttonStyle = contentStyle`
background: #FFFFFF;
box-shadow: 0px 4px 20px 5px rgba(190, 190, 190, 0.25);
border-radius: 10px;
`
const addStyle = contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
// font-size: 25px;
// line-height: 30px;

color: #14A800;
`
const stepDotStyle = contentStyle`
position: absolute;
// margin-left: -50px;
// margin-top: -30px;
box-sizing: border-box;

// position: absolute;
// width: 400px;
// height: 80px;
// left: 190px;
// top: 1606px;

background: #FFFFFF;
border: 1px solid #C6C6C6;
border-radius: 50px;
`;

const nextButtonStyle = contentStyle`
background: #14A800;
border-radius: 50px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
// font-size: 25px;
// line-height: 30px;

color: #FFFFFF;

`
const ruleTitleStyle = contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
text-align: center;

color: #000000;
`
const DrawerPanel = () => {
  const {
    isOpenDrawer, changeDrawer
  } = useAppContext();

  // const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('bottom');

  const showDrawer = () => {
    // setOpen(true);
    changeDrawer(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    changeDrawer(false);
    // setOpen(false);
  };

  return (
    <>
      {/* <Space>
        <Radio.Group value={placement} onChange={onChange}>
          <Radio value="top">top</Radio>
          <Radio value="right">right</Radio>
          <Radio value="bottom">bottom</Radio>
          <Radio value="left">left</Radio>
        </Radio.Group>
        <Button type="primary" onClick={showDrawer}>
          Open
        </Button>
      </Space> */}
      <Drawer
        title={<div className='flex'>
          <Button style={closeButtonStyle}><LeftOutlined /></Button>

          <div className='w-[100%]' style={setTimeStyle}>Lesson options</div>

        </div>}
        placement={placement}
        width={500}
        height={'100vh'}
        closeIcon={false}
        open={isOpenDrawer}
        extra={
          <Space>
            <Button style={closeButtonStyle} onClick={onClose}><CloseOutlined /></Button>
            {/* <Button type="primary" onClick={onClose}>
            OK
          </Button> */}
          </Space>
        }
      >
        {/* <Divider></Divider> */}
        
        
        <div>
          <div style={ruleTitleStyle}>
            Lessons rules
          </div>
          <div style={ruleStyle}>


            Lessons must be scheduled in advance.
            Date and time for at least 1 lesson should be selected to purchase a Package.
            Lessons in a package must be scheduled within 6 months of purchase date.
          </div>
        </div>
        <Divider></Divider>
        <div className='flex flex-wrap justify-center gap-10'>
          <div className='w-[300px] flex flex-col gap-5'>
            <div style={sectionTitleStyle}>30 min</div>
            <><DownOutlined></DownOutlined></>
            <Button className='p-5 flex justify-between items-center' style={buttonStyle}>
              <div>1 lesson</div>
              <div>$ 18.00</div>
            </Button>
            <Button className='p-5 flex justify-between items-center' style={buttonStyle}>
              <div>10 lessons</div>

              <div className='flex gap-3'>
                <div>$ 17.00</div>
                <div style={addStyle}>SAVE 6%</div>
              </div>
            </Button>
          </div>
          <div className='w-[300px] flex flex-col gap-5'>
            <div style={sectionTitleStyle}>30 min</div>
            <><DownOutlined></DownOutlined></>
            <Button className='p-5 flex justify-between items-center' style={buttonStyle}>
              <div>1 lesson</div>
              <div>$ 18.00</div>
            </Button>
            <Button className='p-5 flex justify-between items-center' style={buttonStyle}>
              <div>10 lessons</div>

              <div className='flex gap-3'>
                <div>$ 17.00</div>
                <div style={addStyle}>SAVE 6%</div>
              </div>
            </Button>
          </div>
        </div>
        <Divider></Divider>
        <Schedule />
        <Divider></Divider>
        <Payment></Payment>
        <Divider></Divider>
        <div className='flex w-full'>
          <div className='w-[100px]'>
            <img src={startAvatar} ></img>
          </div>
          <div className='w-full flex flex-wrap justify-between items-center'>
            <Steps
              className='w-[70%]'
              progressDot
              current={2}
              items={[
                {
                  title: <Button className='mt-[0px] ml-[0px] sm:mt-[-30px] sm:ml-[-50px]' style={stepDotStyle}>Fitness-stretching-morning</Button>,
                  // description: 'This is a description.',
                },
                {
                  title: <Button className='mt-[0px] ml-[0px] sm:mt-[-30px] sm:ml-[-50px]' style={stepDotStyle}>60 mins  -  1 lesson</Button>,
                  // description: 'This is a description.',
                },
                {
                  title: <Button className='mt-[0px] ml-[0px] sm:mt-[-30px] sm:ml-[-50px]' style={stepDotStyle}>Thu  Jun 15 - 09:30 - 10:30</Button>,
                  // description: 'This is a description.',
                },
              ]}
            />
            <div className='flex gap-5'>
              <div className=' text-green-500'>
                $ 50.00
              </div>
              <Button style={nextButtonStyle}>Next</Button>
            </div>
            {/* <Button>Fitness-stretching-morning</Button> */}
          </div>
        </div>
        
      </Drawer>
    </>
  );
};

export default DrawerPanel;