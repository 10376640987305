import logoImage from "./../assets/images/logo.png";

const Logo = () => {
  return <div>
    <img 
    className="h-7"
    src={logoImage} style={{
      // width:100
      width:"100%",
      pointerEvents:"none"

    }}
    alt="logo"
    />
    </div>;
};

export default Logo;
