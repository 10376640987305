import Wrapper from '../../assets/wrappers/LandingPage';

import  Header  from '../../Layouts/Header';
import FindContent from '../../Layouts/FindContent';
import Footer from '../../Layouts/Footer';
import RoomContent from '../../Layouts/RoomContent';


const Room = () => {
  return (
    <Wrapper>
        <RoomContent></RoomContent>
        <Footer></Footer>
    </Wrapper>
  );
};

export default Room;
