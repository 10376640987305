import { contentStyle } from "../fuctions";

const titleStyle=contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 60px;
line-height: 73px;
/* identical to box height */


color: #000000;
`;

const QuestionSectionTitle = () => {
    return <p style={titleStyle}>
        Frequently asked question
      </p>;
  };
  
  export default QuestionSectionTitle;
  