import React, { useState, useEffect } from 'react';
import Logo from "../components/Logo";
import FormRow from "../components/FormRow";
import Alert from "../components/Alert";

import Wrapper from '../assets/wrappers/Auth';
import AuthBoard from '../components/AuthBoard';


import Steps from '../components/Steps';
import { useAppContext } from '../context/appContext';
import { useNavigate } from 'react-router-dom';

const initialState = {
  name: '',
  email: '',
  password: '',
};

const Register = () => {
  const [values, setValues] = useState(initialState);

  return (
    <Wrapper>
      <AuthBoard></AuthBoard>
      <Steps></Steps>
    </Wrapper>
  );
};

export default Register;