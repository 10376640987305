import Wrapper from '../../assets/wrappers/OfferWrap';
import Panel from '../../components/Panel';

const UseDevice = () => {
  return (
    <Wrapper>
        <Panel></Panel>
    </Wrapper>
  );
};

export default UseDevice;
