import React, { useRef, Fragment } from "react";

import Wrapper from "../assets/wrappers/FeedbackCarouselWrap";


import { contentStyle } from "../fuctions";
import { feedbackList } from "../data/constants";

import { LeftOutlined, RightOutlined, CheckCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Rate, Checkbox } from 'antd';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const flowButtonStyle = contentStyle`

position: absolute;
width: 100px;
height: 100px;
border-radius: 50%;
background-color: lightGreen;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 36px;
text-align: center;

color: #000000;
`;

const CarouselWrap = contentStyle`
padding-left:5%;
padding-right:5%;

display:flex;
flex-wrap: wrap;
gap:30px;

justify-content: center;
`;

const cardStyle = contentStyle`
// padding-top: 5%;
// margin-top: 3%;
margin-bottom: 3%;
margin-left:13.3%;
// position: absolute;
width: 300px;
height: 250px;

background: #FFFFFF;
box-shadow: 4px 4px 10px 5px rgba(204, 204, 204, 0.25);
border-radius: 5px;
`;
const titleStyle = contentStyle`
// position:absolute;
// margin-top: 5%;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 25px;
// line-height: 36px;

color: #000000;


`;

const descriptionStyle = contentStyle`

// margin-top: 10%;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 30px;
text-align: center;

color: #090909;
`;

const nameStyle = contentStyle`
margin-top: 5%;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 20px;

color: #000000;

`;

const FeedbackCard = (props) => {
    return <div style={cardStyle}>
        <div style={
               contentStyle`
               gap: 15px;
               display: flex;
               flex-direction: column;
               flex-wrap: wrap;`
    }>
            <div style={{
                marginTop:"10px",    height: 40
            }}>
                <Rate disabled defaultValue={2.5} style={{
                    color: '#2ddd2d',
                    fontSize: "20px"
                }} />
                <span style={{
                    fontSize: "20px",
                    fontFamily: 'Inter',
                    lineHeight: '24px'
                }}>
                    <CheckCircleOutlined style={{
                        marginLeft: "10%",
                        fontSize: "20px",
                        borderRadius: "50%",
                        color: 'white',
                        backgroundColor: "#2ddd2d"
                    }} /> verified
                </span>
            </div>
            <div style={titleStyle}>
                {props.item.title}
            </div>
            <div style={descriptionStyle}>
                {props.item.description}
            </div>
            <div style={nameStyle}>
                {props.item.name}, {props.item.postDate}
            </div>
        </div>
    </div>;
}

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const FeedbackCarousel = () => {
    const slider = useRef(null);
    return <Wrapper>
        <Carousel
            responsive={responsive}
            // autoPlay={true}
            customDot={7}
        >
            {feedbackList.map((i, index) => {
                return <FeedbackCard key={index} item={i}></FeedbackCard>;
            })}
        </Carousel>
        </Wrapper>;
};

export default FeedbackCarousel;