import Wrapper from '../../assets/wrappers/LandingPage';

import  Header  from '../../Layouts/Header';
import FindContent from '../../Layouts/FindContent';
import Footer from '../../Layouts/Footer';
import GroupClassContent from '../../Layouts/GroupClassContent';


const GroupClass = () => {
  return (
    <Wrapper>
        <GroupClassContent></GroupClassContent>
        <Footer></Footer>
    </Wrapper>
  );
};

export default GroupClass;
