import React from 'react';
import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, message } from 'antd';
import { Link } from 'react-router-dom';
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import Wrapper from '../assets/wrappers/SignInFormCard';
// import AppleLogin from 'react-apple-login';
// import TwitterLogin from 'react-twitter-login';
import {FacebookLoginButton,TwitterLoginButton,GoogleLoginButton,AppleLoginButton, } from 'react-social-login-buttons';
import { contentStyle } from '../fuctions';
// import { Wrapper } from '../assets/wrappers/SignInFormCard';

const helloStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 50px;
line-height: 63px;
/* identical to box height */


color: #000000;
`;
const introStyle = contentStyle`
margin-bottom:20px;

font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 32px;
/* identical to box height */


color: #000000;
`;
const loginButtonStyle = contentStyle`
background: linear-gradient(90deg, #14A800 0%, #00D514 100%);
border-radius: 10px;
`;

const responseFacebook = (response) => {
  console.log(response);
}

const SignInForm = () => {
  const onFinish = (values) => {
    message.error("No Server!")
    console.log('Received values of form: ', values);
  };

  const handleLogin = async googleData => {
    const res = await fetch('http://localhost:3001/api/v1/auth/google', {
        method: "POST",
        body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    
    console.log( await res.json() )//.then((res)=>{console.log(res)})
    // store returned user somehow
  }

  return (
    <Wrapper>
    <div style={{width:'70%', maxWidth:500, display: "flex", flexDirection:"column", justifyContent:"center"}}>
      <div style={helloStyle}>Hey Hello,</div>
      <div style={introStyle}>Enter the information you entered while registering</div>
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      style={{ minWidth: "100%",}}
    >
      <Form.Item
        name="Email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input prefix={<MailOutlined className="site-form-item-icon" />} 
        size='large'
        placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          size='large'
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" style={{float:"left"}}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <a className="login-form-forgot text-green-500" href="">
          Forgot password
        </a>
      </Form.Item>

      <Form.Item>
        <Button type="primary"
        style={loginButtonStyle}
        size='large' htmlType="submit" className="login-form-button">
          Log in
        </Button>
        Or 
        <Link to='/signup' className="text-green-500"> register now!</Link>
        <Divider>Or</Divider>
        {/* <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Log in with Google"
          onSuccess={handleLogin}
          onFailure={handleLogin}
          cookiePolicy={'single_host_origin'}
        /> */}
        {/* <AppleLogin clientId="com.react.apple.login" redirectURI="https://redirectUrl.com" /> */}
        {/* <FacebookLogin
 appId="1088597931155576"
 autoLoad={true}
 fields="name,email,picture"
//  onClick={componentClicked}
 callback={responseFacebook}
  /> */}
            <GoogleLoginButton 
            // text='' style={{
            //   width:"auto"
            // }}
            ></GoogleLoginButton>
            <FacebookLoginButton></FacebookLoginButton>
            <AppleLoginButton></AppleLoginButton>
            <TwitterLoginButton></TwitterLoginButton>
            
            
      </Form.Item>
    </Form>
    </div>
    </Wrapper>
  );
};

export default SignInForm;