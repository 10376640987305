import { Button } from 'antd';
import Wrapper from '../../assets/wrappers/OfferWrap';
import OfferSectionTitle from '../../components/OfferSectionTitle';
import OfferTypeCards from '../../components/OfferTypeCards';
import { contentStyle } from '../../fuctions';

const titleStyle=contentStyle`
display: flex;
justify-content: space-between;
font-family: 'Outfit';
font-style: normal;
// font-weight: 500;
font-size: 20px;
// line-height: 32px;
/* identical to box height */


color: #000000;
`
const bookTrialButtonStyle =  contentStyle`
background: #14A800;
border-radius: 10px;

font-family: 'Outfit';
font-style: normal;
font-weight: 700;
// font-size: 25px;
// line-height: 32px;
/* identical to box height */


color: #F7F7F7;
`
const contactButtonStyle = contentStyle`
background: #FFFFFF;
border: 1px solid #A7A7A7;
border-radius: 10px;

font-family: 'Outfit';
font-style: normal;
font-weight: 500;
// font-size: 25px;
// line-height: 32px;
/* identical to box height */


color: #454545;
`;
const ContactTeacher = () => {
    return (
        <div className='w-[500px]'>
            <div className='[width:auto]'>
                <video src={"https://www.material-tailwind.com/demo.mp4"} className="h-full w-full rounded-lg" controls='PlayPause'>
                </video>
            </div>
            <div style={titleStyle}>
                <div >Trial lesson</div>
                <div>USD 20</div>
            </div>
            <div className="flex flex-col gap-5 ">
                <Button style={bookTrialButtonStyle}>Book trial</Button>
                <Button style={contactButtonStyle}>Contact teacher</Button>
            </div>

        </div>
    );
};

export default ContactTeacher;
