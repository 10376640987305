import styled from 'styled-components';

const Wrapper = styled.div`
// width:50%;

justify-content:center;
display:flex;
// align-items:center;
box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.0001);
`;

export default Wrapper;
