import { contentStyle } from "../fuctions";
import Wrapper from "../assets/wrappers/OfferTypeWrap";
import { Link } from "react-router-dom";
import { RightOutlined} from '@ant-design/icons';

import {coachTypeList} from "./../data/constants";

const cardStyle = contentStyle`
width: 300px;
`;

const pictureWrapStyle = contentStyle`
width: 300px;
height: 200px;
background: #FFFFFF;
`;


const pictureStyle = contentStyle(`
position: absolute;
width:250px;

// margin-left:-200px;
margin-top:25px;

border-radius: 20px;
`);

const titleStyle = contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 61px;
/* identical to box height */


color: #000000;
`;

const descriptionStyle =contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
text-align: center;

color: #090909;
`;

const linkStyle = contentStyle`
display: inline-flex;
align-items: center;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
// font-size: 30px;
line-height: 36px;
text-align: center;

color: #14A800;
`;
const OfferTypeCard = (props) => {
    return <>
    <div style={cardStyle}>
    <div style={pictureWrapStyle}>
        {/* <div style={getPictureStyle(props.item.image)}></div> */}
        <img src={props.item.image} alt={props.item.image} style={pictureStyle} />
   </div>
   <div>
        <p style={titleStyle}>{props.item.title}</p>
        <p style={descriptionStyle}>{props.item.description}</p>
   </div>
   <Link style={linkStyle}>{props.item.goTo} <RightOutlined/></Link>
   </div>
      </>;
};

const OfferTypeCards = () => {
    return <Wrapper>
        {coachTypeList.map((i,index)=>{
            return <OfferTypeCard key={index} item={i}></OfferTypeCard>;
        })}
        
      </Wrapper>;
  };
  
export default OfferTypeCards;
  