import React from 'react';
import travel from '../assets/images/travel.svg';
// import Wrapper from '../assets/wrappers/AuthBoardWrap';
const TravelMode = () => {
  return (
  <>
        <img src={travel} alt='job hunt'
    style={{
        // height:"80vh",
        // height:"80VH",
        // margin:"10%",
        marginTop:"10%",
        width:"100%"
    }} />
  </>);
};

export default TravelMode;
