import React from 'react';
import { Outlet } from 'react-router-dom';
// import Wrapper from '../../assets/wrappers/SharedLayout';
// import { SmallSidebar, BigSidebar, Navbar } from '../../components';

const SharedLayout = () => {
  return (
    <div>erweere
    {/* <Wrapper>
      <main className='dashboard'>
        <SmallSidebar />
        <BigSidebar />
        <div>
          <Navbar />
          <div className='dashboard-page'>
            <Outlet />
          </div>
        </div>
      </main>
    </Wrapper> */}
    </div>
  );
};

export default SharedLayout;
