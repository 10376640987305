// import logoImage from "./../assets/images/logo.png";
import { Input } from 'antd';
import { contentStyle } from '../fuctions';
import { SearchOutlined } from '@ant-design/icons';
const SearchInputStyle=contentStyle`
1box-sizing: border-box;
width: 50%;
//   height: 50px;
font-size:16px;
background: #FFFFFF;
border: 1px solid #CCCCCC;
border-radius: 50px;
`
const SearchInput = () => {
  return <div>
    <Input
prefix={<SearchOutlined />}
    placeholder='Search coaches / lessons / group class'
    style={SearchInputStyle}
></Input>
    </div>;
};

export default SearchInput;


