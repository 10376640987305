import { contentStyle } from "../fuctions";
import logoImage from "./../assets/images/logo.png";
import { Button, Input } from 'antd';
import { SendOutlined } from "@ant-design/icons"

const { TextArea } = Input;


const cardStyle = contentStyle`
background: #FFFFFF;
border: 1px solid #DDD5D5;
border-radius: 20px;
`
const leftMessageStyle = contentStyle`
max-width: 70%;
background: #F0EFEF;
border-radius: 50px 50px 0px 50px;
`
const rightMessageStyle = contentStyle`
max-width: 70%;
background: #F0EFEF;
border-radius: 50px 50px 0px 50px;
`
const nameStyle = contentStyle`
text-align: left;
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 25px;
line-height: 32px;
/* identical to box height */


color: #8B8B8B;

`
const messageStyle = contentStyle`
text-align: left;
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 25px;
// line-height: 32px;

color: #000000;
`
const inputCardStyle = contentStyle`
background: #FFFFFF;
border-radius: 100px;
`
const sendButtonStyle = contentStyle`
box-sizing: border-box;
height: 45px;
background: rgb(20, 168, 0);
border: 1px solid rgb(20, 168, 0);
border-radius: 50%;
display: flex;
align-items: center;
color: rgb(255, 255, 255);
`
const Chat = () => {
    return <div className="pt-0" style={cardStyle}>
        <div className="p-5">
            <div className="flex flex-col gap-5 overflow-y-auto h-[200px]">


                <div className="flex justify-start">
                    <div className="px-5" style={leftMessageStyle}>
                        <div style={nameStyle}>Emmy</div>
                        <div style={messageStyle}>Hello Do you get me in the video</div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <div className="px-5" style={rightMessageStyle}>
                        <div style={nameStyle}>you</div>
                        <div style={messageStyle}>Yes I get you and I am ready to hear more from you</div>
                    </div>
                </div>
                <div className="flex justify-start">
                    <div className="px-5" style={leftMessageStyle}>
                        <div style={nameStyle}>Emmy</div>
                        <div style={messageStyle}>I kindly greet you and welcome you in our services. We are going to do the program with many  ideas.</div>
                    </div>
                </div>
                <div className="flex justify-start">
                    <div className="px-5" style={leftMessageStyle}>
                        <div style={nameStyle}>Emmy</div>
                        <div style={messageStyle}>I kindly greet you and welcome you in our services. We are going to do the program with many  ideas.</div>
                    </div>
                </div>
                <div className="flex justify-start">
                    <div className="p-5" style={leftMessageStyle}>
                        <div style={nameStyle}>Emmy</div>
                        <div style={messageStyle}>I kindly greet you and welcome you in our services. We are going to do the program with many  ideas.</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="p-3 pt-0 flex items-center" style={inputCardStyle}>
            <TextArea style={{ borderRadius: 30, overflowY: "hidden" }} placeholder="Type your message here..." />
            <div>😀</div>
            <Button style={sendButtonStyle}><SendOutlined /></Button>
        </div>

    </div>;
};

export default Chat;
