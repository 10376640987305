import Wrapper from "../assets/wrappers/Coaches";
import cardImage from "./../assets/images/cardImage.svg";
import playImage from "./../assets/images/play.svg";

import { contentStyle } from "../fuctions";
import { coachList } from "../data/constants";
import { Tag } from "antd";
import { useAppContext } from "../context/appContext";
const cardStyle = contentStyle`
// margin: 10px;

width: 300px;
// height: 450px;

background: #FFFFFF;
border-radius: 20px;

box-shadow: 1px 1px 10px -5px;
`;

const cardImageStyle = contentStyle(`
width: 400px;
height: 332px;
// left: 75px;
// top: 1277px;
background: url(${cardImage}), #D9D9D9;
border-radius: 20px;
background-repeat: no-repeat;
`);

const newCardImageStyle = contentStyle`
// padding:100px;
// margin:10px;
// width: 300px;
// height: 100px;
// zoom:0.5;
background: grey;
`;

const newPlayImageStyle = contentStyle`
position: absolute;
width: 100px;
height: 100px;
margin-top: -141px;
margin-left: 100px;
`;

const descriptionStyle = contentStyle`
text-align: left;

font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 32px;

color: #000000;
`;

const clientStyle = contentStyle`
text-align: left;

// position: absolute;
// width: 299px;
// height: 75px;
// left: 75px;
// top: 1780px;

font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 14px;
line-height: 25px;

color: #575757;
`;
const coachTypeStyle=contentStyle`
    
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
// font-size: 20px;
// line-height: 25px;

color: #000000;
`
const rateStyle =contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
// font-size: 20px;
line-height: 25px;

color: #000000;
`
const CoachCard = (props) => {

    const {
        isOpenDrawer, changeDrawer
      } = useAppContext();

    function openDrawer (){
        changeDrawer(true);
    }
    
    return <>
        <div key={props.item} style={cardStyle} onClick={openDrawer}
            onDragStart={(e) => { e.preventDefault(); }}
        >
            <div className="grid min-h-[140px] w-full place-items-center 
                        [overflow-x-scroll]
                        rounded-lg p-0 lg:overflow-visible"
            >
                <video src={"https://www.material-tailwind.com/demo.mp4"} className="h-full w-full rounded-lg" controls='PlayPause'>
                    {/* <source src="https://www.material-tailwind.com/demo.mp4" type="video/mp4" />Your browser does not support the video tag. */}
                </video>
            </div>
            <img src={playImage} style={newPlayImageStyle} alt="playImage"
            />

            <div className="p-4 flex inline-flex flex-col gap-1">
                <div style={descriptionStyle}>I want to help you become physically fit</div>
                <div className="flex  gap-5" style={coachTypeStyle}>
                    <div >
                        Fitness
                    </div>
                    <div>
                        Wellness
                    </div>
                    <div>
                        <Tag>3+</Tag>
                    </div>
                </div>
                <div className="flex  gap-5" style={rateStyle}>
                    <div>
                        $  7.0
                    </div>
                    <div>
                        ⭐ 5.0(500)
                    </div>
                </div>

                <div style={clientStyle}>Have five years of experience in GYM and fitness practices, and also self fit</div>
            </div>
        </div>
    </>;
}

const CoachCards = () => {
    return <Wrapper>
        {coachList.map((i, index) => {
            return <CoachCard key={index} item={i}></CoachCard>;
        })}
    </Wrapper>;
};

export default CoachCards;