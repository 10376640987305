import { Divider, Input, Select } from "antd";

const handleChange = (value) => {
    console.log(`selected ${value}`);
};

const Payment = () => {

    return <>
        <div className="flex justify-center">
            <div className="w-full min-w-[500px] max-w-[700px]">
                <div>Payment method</div>
                <Select
                    defaultValue="Paypal"
                    style={{
                        width: 120,
                    }}
                    onChange={handleChange}
                    options={[
                        {
                            value: 'Paypal',
                            label: 'Paypal',
                        },
                        {
                            value: 'payoneeor',
                            label: 'payoneeor',
                        },
                        {
                            value: 'crypto',
                            label: 'crypto',
                        },
                        {
                            value: 'disabled',
                            label: 'Disabled',
                            disabled: true,
                        },
                    ]}
                />
                <div>
                    <div>Promo code</div>
                    <Input className="w-1/2" placeholder="xxx"></Input>
                </div>

                <div className="flex gap-10">
                    <div>
                        <div>Expiry date:</div>
                        <Input placeholder="mm  /  yy"></Input>
                    </div>
                    <div>
                        <div>CVV</div>
                        <Input placeholder="xxx"></Input>
                    </div>
                </div>
                <div className="flex justify-between">
                    <div>Subtotal amount:</div>
                    <div>$296</div>
                </div>
                <div className="flex justify-between">
                    <div>Platform fee:</div>
                    <div>$4</div>
                </div>
                <Divider></Divider>
                <div className="flex justify-between">
                    <div>Total:</div>
                    <div>$300</div>
                </div>
            </div>
        </div>
    </>;
};

export default Payment;
