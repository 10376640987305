import React from 'react';
import Wrapper from '../../assets/wrappers/ShowCoachWrap';
import ShowCoachTitle from "../../components/ShowCoachTitle";
import AspectTypeList from '../../components/AspectTypeList';
import CoachCards from '../../components/CoachCards';
import FindMore from '../../components/FindMore';
const ShowCoach = () => {
  const [currentAspect, setAspect] = React.useState(-1);

  const selectAspect = (i) => {
    setAspect(i);
  }

  return (
    <Wrapper>
      <div 
      // className="max-w-7xl px-4 mx-auto sm:px-6"
      >
        <div 
        style={{display:"flex", flexDirection:"column", gap:20}}
        >
          <ShowCoachTitle />
          <AspectTypeList currentAspect={currentAspect} selectAspect={selectAspect}></AspectTypeList>
          <CoachCards></CoachCards>
          <FindMore></FindMore>
        </div>
      </div>
    </Wrapper>
  );
};

export default ShowCoach;