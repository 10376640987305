import Wrapper from '../../assets/wrappers/QuickAccessWrap';
import Links from '../../components/Links';
import LSet from '../../components/LSet';
const QuickAccess = () => {
  return (
    <Wrapper>
      <div className="max-w-7xl px-4 mx-auto sm:px-6  bg-green  ">
        <div className="flex justify-center">
        <Links></Links>
        <LSet></LSet>
        </div>
        </div>
    </Wrapper>
  );
};

export default QuickAccess;
