import Wrapper from '../../assets/wrappers/OfferWrap';
import LessonsView from '../../components/LessonsView';
import OfferSectionTitle from '../../components/OfferSectionTitle';
import OfferTypeCards from '../../components/OfferTypeCards';
import Similar from '../../components/Similar';

const OtherLessons = () => {
    return (
        <div className="max-w-7xl px-4 mx-auto sm:px-6">
            <div
                className="py-12 md:py-20"
            >
                <Similar></Similar>
            </div>
        </div>
    );
};

export default OtherLessons;
