import { contentStyle } from "../fuctions";
import { StarFilled } from "@ant-design/icons"
const stateViewCardStyle = contentStyle`
display: flex;
grid-gap: 20px;
justify-content: center;
// position: absolute;
// width: 838px;
// height: 150px;
// left: 200px;
// top: 1119px;

background: #FFFFFF;
box-shadow: 0px 4px 20px 5px rgba(190, 190, 190, 0.25);
border-radius: 20px;
`;
const rateStyle=contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 38px;
/* identical to box height */


color: #000000;
`;
const rateTitleStyle=contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 25px;

color: #000000;
`;
const StateView = () => {
    return <>
        <div className="p-5 w-full lg:w-[50%] md:w-[70%]" style={stateViewCardStyle}>
            <div >
                <div style={rateStyle}>
                    ⭐ 5.0
                </div>
                <div style={rateTitleStyle}>
                    Rating
                </div>
            </div>
            <div>
                <div style={rateStyle}>
                    1004
                </div>
                <div style={rateTitleStyle}>
                    Trainees
                </div>
            </div>
            <div>
                <div style={rateStyle}>
                    11,200
                </div>
                <div style={rateTitleStyle}>
                    Lessons
                </div>
            </div>
            <div>
                <div style={rateStyle}>
                    100%
                </div>
                <div style={rateTitleStyle}>
                    Attendance
                </div>
            </div>
            <div>
                <div style={rateStyle}>
                    100%
                </div>
                <div style={rateTitleStyle}>
                    Response
                </div>
            </div>
        </div>
    </>;
};

export default StateView;
