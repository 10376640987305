
import { contentStyle } from "../fuctions";
import profileAvatar from "./../assets/images/profileAvatar.png";
import { Button, Tabs } from 'antd';
import LessonsView from "./LessonsView";
import StateView from "./StateView";
import Availability from "./Availability";
import Reviews from "./Reviews";
import { ShareAltOutlined, HeartFilled } from "@ant-design/icons";
const profileCardStyle = contentStyle`
// position: absolute;
// width: 1000px;
// height: 500px;
// left: 189px;
// top: 240px;

background: #FFFFFF;
box-shadow: 0px 4px 20px 5px rgba(190, 190, 190, 0.25);
border-radius: 20px;
`;
const profileAvatarStyle = contentStyle`
// position: absolute;
width: 100px;
height: 100px;
// left: 250px;
// top: 277px;

// background: url(.jpg), #D9D9D9;

`;

const profileCardHeaderStyle = contentStyle`
// display: inline-flex;
`;

const profileNameStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 25px;
// line-height: 32px;
/* identical to box height */


color: #000000;
`;
const roleStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 25px;
// line-height: 32px;
/* identical to box height */


color: #A9A9A9;
`;
const coachStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 20px;
// line-height: 25px;

color: #000000;
`;

const coachTypeListStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 20px;
// line-height: 25px;

color: #14A800;
`;
const coverLetterStyle = contentStyle`
text-align: justify;
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 25px;

color: #000000;
`;

const stateStyle=contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 20px;
line-height: 25px;

color: #000000;

`
const callButtonStyle = contentStyle`
box-sizing: border-box;
    height: 45px;
    background: #14A800;
    border: 1px solid rgb(20, 168, 0);
    border-radius: 50%;
    display: flex;
    align-items: center;
    color: #ffffff;
`
const tabTitleStyle=contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
// font-size: 20px;
// line-height: 25px;

color: #000000;
`
const ProfileView = () => {
    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: <div style={tabTitleStyle}>About me</div>,
            children: <div className="p-5" style={coverLetterStyle}>
                {`From France 
Living in Eindhoven, 
Netherlands (16:36 UTC+02:00)

About Me                                                                                 
                                                                                                 Coach since Nov 26, 2021


My name is Claire, I'm 27 years old. I am a native French speaker, graduated with a Master's degree in teaching French as a foreign language. I love coaching fitness so I know how hard it is. Beside that, I like sports, watching movies, reading, drawing... I am sure we'll have something in common to talk about :)`}
            </div>,
        },
        {
            key: '2',
            label: <div style={tabTitleStyle}>Lessons</div>,
            children: <>
                <LessonsView />
            </>,
        },
        {
            key: '3',
            label: <div style={tabTitleStyle}>Availability</div>,
            children: <>

                <Availability /></>,
        },
        {
            key: '4',
            label: <div style={tabTitleStyle}>Reviews</div>,
            children: <>
                <Reviews /></>,
        },
    ];

    return <>
        <div className="p-5 w-full" style={profileCardStyle}>
            <div className=" flex gap-10 justify-between" style={profileCardHeaderStyle}>
                <div className=" flex gap-10 justify-between">
                    <div>
                        <img className="" style={profileAvatarStyle} src={profileAvatar}></img>
                        <div style={stateStyle}>
                            <span>😀</span>
                            <span>online</span>
                        </div>
                    </div>
                    <div>
                        <div className=" text-left" style={profileNameStyle}>
                            John Doe
                        </div>
                        <div className=" text-left" style={roleStyle} >
                            PROFESSIONAL COACH
                        </div>
                        <div className="flex justify-between">
                            <span style={coachStyle}>Coaches</span>
                            <span style={coachTypeListStyle}>FITNESS</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex gap-3">
                        <Button style={callButtonStyle}><ShareAltOutlined /></Button>
                        <Button style={callButtonStyle}><HeartFilled /></Button>
                    </div>
                </div>
            </div>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
    </>;
};

export default ProfileView;
