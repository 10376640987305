import React from "react";
import Wrapper from '../assets/wrappers/LandingPage';

import LandingContent from '../Layouts/Content';
import LandingFooter from '../Layouts/Footer';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const Landing = () => {
  React.useEffect(() => {
    // AOS.init({
    //   once: true,
    //   disable: 'phone',
    //   duration: 600,
    //   easing: 'ease-out-sine',
    // })
  })
  return (
    <Wrapper>
        <LandingContent></LandingContent>
        <LandingFooter></LandingFooter>
    </Wrapper>
  );
};

export default Landing;
