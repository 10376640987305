import { Button } from "antd";
import { contentStyle } from "../fuctions";
import logoImage from "./../assets/images/logo.png";
import { LeftOutlined, PhoneOutlined, CloseOutlined } from '@ant-design/icons';
const barStyle = contentStyle`
// position: absolute;
// width: 2406px;
// height: 171px;
// left: 50px;
// top: 25px;

background: #FFFFFF;
box-shadow: 4px 4px 20px 10px rgba(187, 187, 187, 0.25);
border-radius: 100px;
`;
const backButtonStyle = contentStyle`
box-sizing: border-box;
height: 45px;
background: rgb(255, 255, 255);
border: 1px solid rgb(20, 168, 0);
border-radius: 50%;
display: flex;
align-items: center;
`
const callButtonStyle = contentStyle`
box-sizing: border-box;
height: 45px;
background: #14A800;
border: 1px solid rgb(20, 168, 0);
border-radius: 50%;
display: flex;
align-items: center;
color: #ffffff;
`
const closeButtonStyle = contentStyle`
box-sizing: border-box;
height: 45px;
background: #FF0000;
border: 1px solid rgb(255, 0, 0);
border-radius: 50%;
display: flex;
align-items: center;
color: #ffffff;
`
const nameStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
// font-size: 40px;
// line-height: 50px;

color: #000000;
`;
const stateStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
// font-size: 30px;
// line-height: 38px;
/* identical to box height */


color: #14A800;
`;
const descriptionStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 20px;
// line-height: 44px;
/* identical to box height */
color: #000000;
`
const controlStyle = contentStyle`

`
const RoomCallBar = () => {
    return <>
        <div className="w-full">
            <div style={barStyle} className="p-2 w-full flex justify-between">
                <div className="flex gap-3">
                    <Button style={backButtonStyle}><LeftOutlined /></Button>
                    <div>
                        <div style={nameStyle}>John’s Coach Room</div>
                        <div style={stateStyle}>John’s training</div>
                    </div>
                </div>
                <div className="flex items-center gap-3" style={controlStyle}>
                    <div style={descriptionStyle}>Emmy Wanted to have a room</div>
                    <Button style={callButtonStyle}>
                        <PhoneOutlined />
                    </Button>
                    <Button style={closeButtonStyle}>
                        <CloseOutlined />
                    </Button>
                </div>
            </div>
        </div>
    </>;
};

export default RoomCallBar;
