import React from 'react';
import { LockOutlined, UserOutlined,MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, message,DatePicker } from 'antd';
import {FacebookLoginButton,TwitterLoginButton,GoogleLoginButton,AppleLoginButton, } from 'react-social-login-buttons';

import { Link } from 'react-router-dom';
import Wrapper from '../assets/wrappers/SignUpFormCard';
// import { Wrapper } from '../assets/wrappers/SignInFormCard';
import { contentStyle } from '../fuctions';
const nextButtonStyle = contentStyle`
background: linear-gradient(90deg, #14A800 0%, #00D514 100%);
border-radius: 10px;
`;

const SignUpForm = (props) => {
  const onFinish = (values) => {
    props.nextClick();
    // message.error("No Server!")
    console.log('Received values of form: ', values);
  };

  return (
    <Wrapper>
    <div style={{ width:'70%'}}>
    <span>
      <p>
      &nbsp;
      </p>
    </span>
    <Form
      name="normal_register"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      style={{ minWidth: "100%", }}
    >
      <Form.Item
        name="FirstName"
        rules={[{ required: true, message: 'Please input your FirstName!' }]}
      >
        <Input 
        size='large'
        placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="LastName"
        rules={[{ required: true, message: 'Please input your LastName!' }]}
      >
        <Input 
        size='large'
        placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="Email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input prefix={<MailOutlined className="site-form-item-icon" />} 
        size='large'
        placeholder="Email" />
      </Form.Item>
      <Form.Item
        // name="Birth"
        rules={[{ required: true, message: 'Please input your Birthday!' }]}
        label="Birthday"
      >
        <DatePicker />
      </Form.Item>
      
      <Form.Item>
        <Button type="primary"
        style={nextButtonStyle}
        size='large' htmlType="submit" className="login-form-button">
          Next
        </Button>
        Already Have an account? 
        <Link to='/signIn' className="text-green-500"> SignIn</Link>
      </Form.Item>
      <Divider>Or</Divider>
        {/* <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Log in with Google"
          onSuccess={handleLogin}
          onFailure={handleLogin}
          cookiePolicy={'single_host_origin'}
        /> */}
        {/* <AppleLogin clientId="com.react.apple.login" redirectURI="https://redirectUrl.com" /> */}
        {/* <FacebookLogin
 appId="1088597931155576"
 autoLoad={true}
 fields="name,email,picture"
//  onClick={componentClicked}
 callback={responseFacebook}
  /> */}
            <GoogleLoginButton 
            // text='' style={{
            //   width:"auto"
            // }}
            ></GoogleLoginButton>
            <FacebookLoginButton></FacebookLoginButton>
            <AppleLoginButton></AppleLoginButton>
            <TwitterLoginButton></TwitterLoginButton>
    </Form>
    </div>
    </Wrapper>
  );
};

export default SignUpForm;