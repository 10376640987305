import Wrapper from '../../assets/wrappers/Intro';
import Hello from '../../components/Hello';
import Picture from '../../components/Picture';


const Intro = () => {
  return (
    <Wrapper>
      <div className="max-w-7xl px-4 mx-auto sm:px-6    ">
        <div className="relative pt-32 pb-10 md:pt-40 md:pb-16 flex justify-center">
          <Hello></Hello>
          <Picture></Picture>
        </div>
      </div>

    </Wrapper>
  );
};

export default Intro;
