import { contentStyle } from "../fuctions";
import Schedule from "./Schedule";


const sectionTitleStyle=contentStyle`
text-align: left;

font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 32px;
/* identical to box height */


color: #000000;
`
const lessonTitleStyle=contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 32px;
/* identical to box height */


color: #000000;
`;
const achievementStyle=contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 19px;
/* identical to box height */


color: #B0B0B0;
`;

const priceStyle = contentStyle`
box-sizing: border-box;

// position: absolute;
// width: 85px;
// height: 28px;
// left: 953px;
// top: 1421px;

background: #FFFFFF;
border: 1px solid #14A800;
border-radius: 50px;
`;
const Availability = () => {
    return <>
        <div className="p-5 w-full flex inline-flex flex-col gap-5">
            <div style={sectionTitleStyle}>Availability</div>
            <div>
                <Schedule/>
            </div>

        </div>

    </>;
};

export default Availability;
