import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';
import { contentStyle } from '../fuctions';

const panelStyle = contentStyle`
width: 80%;

margin-left: 10%;
margin-top: 5px;

// height: 150px;
background: #FFFFFF;
// box-shadow: rgba(e, e, e, 1) 0px 0px 20px 0px;
border-radius: 20px;
// z-index: 100;
`;

const titleStyle = contentStyle`
margin:1%;
float:left;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 28px;
line-height: 34px;
/* identical to box height */


color: #000000;

`;
const descriptionStyle=contentStyle`
margin:1%;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;

color: #000000;

`;
const text = `
To become a coach on Coach.me, you first need to create an account on the platform. Then, you should complete your own coaching plan and achieve your goals, while also getting endorsements from other users. Once you have established yourself as a credible and effective coach, you can click on the "Become a Coach" button on your profile page. This will take you to an application form where you will need to provide information about your coaching experience, skills, and specialties. After you submit your application, you will need to wait for approval from the Coach.me team. If your application is approved, you can start coaching clients on the platform and earning money for your services.
`;

const getItems= () => [
  {
    key: '1',
    label: <div     style={titleStyle}     >How to become a coach on coach?</div>,
    children: <div style={descriptionStyle}>{text}</div>,
    style: panelStyle,
  },
  {
    key: '2',
    label: <div     style={titleStyle}     >How to become a coach on coach?</div>,
    children: <div style={descriptionStyle}>{text}</div>,
    style: panelStyle,
  },
  {
    key: '3',
    label: <div     style={titleStyle}     >How to become a coach on coach?</div>,
    children: <div style={descriptionStyle}>{text}</div>,
    style: panelStyle,
  },
];

const Questions = () => {
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  return (
    <  >
    <Collapse
    className="md:[zoom:0.8] [zoom:0.6]"
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined style={{
      fontSize: "30px",
      display: "flex",
      alignItems: "center",
      }} rotate={isActive ? 90 : 0} />}
      style={contentStyle`
      background:none;
      // background:white;
      // background:rgba(0, 0, 0, 225) 0px 0px 200px 0px;
      // box-shadow: rgba(0, 0, 0, 225) 0px 0px 200px 0px;
      // box-shadow: inset rgba(0, 0, 0, 225) 0px 0px 200px 0px;
      // background: radial-gradient(#000000, #ffffff);
      // background-repeat: no-repeat;
// background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.22) 40%, rgba(0,0,0,0.22) 60%, rgba(255,255,255,1) 100%);
      
// background: rgb(255,255,255);
`}
      // style={{ background: token.colorBgContainer }}
      items={getItems(panelStyle)}
    /></>
  );
};

export default Questions;