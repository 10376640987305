import { Button } from "antd";

import { contentStyle } from "../fuctions";
import { RightOutlined} from '@ant-design/icons';


const findMoreStyle =contentStyle`

display: inline-flex;
align-items: center;
// width: 200px;
height: 50px;

background: #14A800;
border-radius: 10px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
// font-size: 16px;
line-height: 30px;

color: #FFFAFA;

align-items: center;
`;

const FindMore = () => {
    return <div>
<Button style={findMoreStyle}>Find More <RightOutlined /></Button>     </div>;
  };
  
  export default FindMore;
  