import React from 'react';
import { Steps } from 'antd';

const description = 'This is a description.';
const StepLine = (props) => (
<div style={{width:"80%", marginLeft:"10%", paddingTop:30}}>
  <Steps
    current={props.currentStep}
    items={[
      {
        title: 'Personal Info',
        // description,
      },
      {
        title: 'Credentials',
        // description,
        // subTitle: '',
      },
      {
        title: 'Finish',
        // description,
      },
    ]}
  />
</div>
);

export default StepLine;