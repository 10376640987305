import React from 'react';
import { LockOutlined, UserOutlined,MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, message,DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import Wrapper from '../assets/wrappers/SignUpFormCard';
import TravelMode from './TravelMode';
// import { Wrapper } from '../assets/wrappers/SignInFormCard';
import { contentStyle } from '../fuctions';
const finishButtonStyle = contentStyle`
background: linear-gradient(90deg, #14A800 0%, #00D514 100%);
border-radius: 10px;
`;

const safeStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 50px;

color: #14A800;
`;

const SignUpForm3 = (props) => {
  const onFinish = (values) => {
    // props.nextClick();
    // message.error("No Server!")
    console.log('Received values of form: ', values);
  };

  return (
    <Wrapper>
    <div style={{ width:'70%'}}>
    <span>
      <p>
      &nbsp;
      </p>
    </span>
    <span style={safeStyle}>
    Have a safe browsing through Platform
    </span>
    <TravelMode/>
    <Form
      name="Finish"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      style={{ minWidth: "100%", }}
    >
      <Form.Item>
        <Button type="primary"
        style={finishButtonStyle}
        size='large' htmlType="submit" className="login-form-button">
          Finish
        </Button>
      </Form.Item>
    </Form>
    </div>
    </Wrapper>
  );
};

export default SignUpForm3;