import styled from 'styled-components'

const Wrapper = styled.div`

    padding-left: 5%;
        padding-right: 5%;
display:flex;
flex-wrap: wrap;
gap:30px;

justify-content: center;
`
export default Wrapper
