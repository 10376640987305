import React from 'react';
import logo from './logo.svg';
import './App.css';
// import 'antd/dist/reset.css';
import './index.css';
import { ProtectedRoute } from './pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { SharedLayout } from './pages/Dashboard';
import { Landing, SignIn, SignUp } from './pages';
/////////////////////Test/////////////////////
import ProfilePanel from './components/ProfilePanel';
import Find from './pages/Dashboard/Find';
import Header from './Layouts/Header';
import GroupClass from './pages/Dashboard/GroupClass';
import ShowGroupCoachCards from './components/ShowGroupCoachCards';
import Profile from './pages/Dashboard/Profile';
import Room from './pages/Dashboard/Room';
import DrawerPanel from './Layouts/Content/DrawerPanel';
import "@progress/kendo-theme-default/dist/all.css";
// import Schedule from './Layouts/Content/Schedule';
// import Schedule from "./components/Schedule"

function App() {

  

  return (
    <div className="App">
       
                {/* <span className="k-icon k-i-loading"></span> */}
                
            
            {/* <div><Schedule/></div> */}
      
      <BrowserRouter>
        <Header/>
        <DrawerPanel />
        <Routes>
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }>
          </Route>
          {/* UnProtect-------->>>  */}
          <Route path='/signin' element={<SignIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/landing' element={<Landing />} />
          {/*  <<<----------------------------------------------- UnProtect */}
          {/* >>> Test */}
          <Route path='/find' element={<Find />} />
          <Route path='/groupClass' element={<GroupClass/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/room' element={<Room/>}/>

          <Route path='/test' element={<><div style={{backgroundColor:"lightGreen",height:"10vh"}}></div><DrawerPanel /></>} />
          {/* Test <<< */}
          <Route path='*' element={'Error'} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
