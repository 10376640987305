import Wrapper from '../../assets/wrappers/Intro';
import RoomCallBar from '../../components/RoomCallBar';
import Summary from '../../components/Summary';
import Chat from '../../components/Chat';
import VideoCall from '../../components/VideoCall';
const RoomCall = () => {
    return (
        <Wrapper>
            <div className="max-w-7xl px-4 mx-auto sm:px-6    ">
                <div className="relative pt-32 pb-10 md:pt-40 md:pb-16 flex flex-col gap-5 justify-center">
                    <RoomCallBar></RoomCallBar>
                    <div className='flex gap-10'>
                        <div className='w-[60%]'>
                            <VideoCall></VideoCall>
                        </div>
                        <div className='w-[40%] flex flex-col gap-5'>
                            <Summary></Summary>
                            <Chat></Chat>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    );
};

export default RoomCall;
