import { Button, Space } from "antd";
import { contentStyle } from "../fuctions";
import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import deviceImage from "./../assets/images/device.png"
const backgroundStyle = contentStyle`
// position: absolute;
width: 100%;
height: 150px;

background: #14A800;
border-radius: 100px 100px 0px 0px;

display: flex;
align-items: center;
justify-content: center;
`;

const titleStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-size: 30px;
color: #FFFFFF;
`;
const androidButtonStyle = contentStyle`
display: inline-flex;
align-items: center;


background: #FFFFFF;
border-radius: 10px;

font-family: 'Outfit';
font-style: normal;
font-weight: 400;
// font-size: 35px;
/* identical to box height */


color: #000000;
`;

const iosButtonStyle = contentStyle`
display: inline-flex;
align-items: center;

background: #FFFFFF;
border-radius: 10px;

font-family: 'Outfit';
font-style: normal;
font-weight: 400;
// font-size: 35px;
/* identical to box height */


color: #000000;

`;

const Panel = () => {
    return <div style={backgroundStyle}>
        <div className="flex items-center" style={{
            width: "40%",
            // padding: '5%',
            // display: "flex",
            // alignItems: "center",
            // gap: "34px",
            // flexWrap: "wrap"
        }}>
            <div className="hidden md:block sm:text-lg" style={titleStyle}>
                Use our Platform on your mobile phone
            </div>
            <div  style={{
                marginLeft:"5%"
                // marginTop: "5%"
            }}>
                <Space wrap>
                    <Button
                        type="primary"
                        style={androidButtonStyle}
                    ><AndroidFilled style={{ color: "green" }} />Android OS</Button>
                    <Button type="text" style={iosButtonStyle}><AppleFilled />IOS</Button>
                </Space>
            </div>

        </div>
        <div style={{
            width: "50%",
            display: "flex",
            padding: "10px",
        }}>
            {/* <img style={{
                 transform: "scale(0.4)"
            }}
                alt="device"
                src={deviceImage}>

            </img>

            <img style={{
                transform: "scale(0.4)"
            }}
                alt="device"
                src={deviceImage}>

            </img> */}
        </div>
    </div>;
};

export default Panel;
