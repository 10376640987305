import Wrapper from '../../assets/wrappers/OfferWrap';
import OfferSectionTitle from '../../components/OfferSectionTitle';
import OfferTypeCards from '../../components/OfferTypeCards';

const Offer = () => {
  return (
    <Wrapper>
      <div className="max-w-7xl px-4 mx-auto sm:px-6">
        <div 
        className="py-12 md:py-20"
        >
      <OfferSectionTitle></OfferSectionTitle>
      <OfferTypeCards></OfferTypeCards>
      </div>
      </div>
    </Wrapper>
  );
};

export default Offer;
