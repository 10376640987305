import kids from "./../assets/images/kids.svg";
import { contentStyle } from "../fuctions";

const pictureWrapStyle = contentStyle`
// display: flex;
  width: 40%;
//   justify-content: center;
`;

const pictureStyle = contentStyle`
width: 80%;
`;

const Picture = () => {
  return <>
    <div 
    className="hidden md:block"
    style={pictureWrapStyle}>
      <img src={kids} alt="kids" style={pictureStyle} />
    </div>
  </>;
};

export default Picture;
