import { contentStyle } from "../fuctions";

const titleStyle=contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 60px;
line-height: 73px;
/* identical to box height */


color: #000000;
`;

const OfferSectionTitle = () => {
    return <p style={titleStyle}>
        What we offer here On coach
      </p>;
  };
  
  export default OfferSectionTitle;
  