import React from 'react';
import { LockOutlined, UserOutlined,MailOutlined ,LockFilled} from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, message,DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import Wrapper from '../assets/wrappers/SignUpFormCard';
// import { Wrapper } from '../assets/wrappers/SignInFormCard';
import { contentStyle } from '../fuctions';
const nextButtonStyle = contentStyle`
background: linear-gradient(90deg, #14A800 0%, #00D514 100%);
border-radius: 10px;
`;

const SignUpForm2 = (props) => {
  const onFinish = (values) => {
    props.nextClick();
    // message.error("No Server!")
    console.log('Received values of form: ', values);
  };

  return (
    <Wrapper>
    <div style={{ width:'70%'}}>
    <span>
      <p>
      &nbsp;
      </p>
      <p >
      &nbsp;
      </p>
    </span>
    <Form
      name="normal_register2"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      style={{ minWidth: "100%", }}
    >
      <Form.Item
        name="Username"
        rules={[{ required: true, message: 'Please input your FirstName!' }]}
      >
        <Input 
        prefix={<UserOutlined className="site-form-item-icon" />}
        size='large'
        placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="Role"
        rules={[{ required: true, message: 'Please input your LastName!' }]}
      >
        <Input 
        size='large'
        placeholder="Role" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          size='large'
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="comfirmPassword"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockFilled className="site-form-item-icon" />}
          type="password"
          size='large'
          placeholder="Confirm Password"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary"
        style={nextButtonStyle}
        size='large' htmlType="submit" className="login-form-button">
          Finish
        </Button>
      </Form.Item>
    </Form>
    </div>
    </Wrapper>
  );
};

export default SignUpForm2;