import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function MobileMenu() {
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

  const trigger = useRef<HTMLButtonElement>(null)
  const mobileNav = useRef<HTMLDivElement>(null)

  // close the mobile menu on click outside
  useEffect(() => {

    const clickHandler = ({ target }: { target: EventTarget | null }): void => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target as Node) || trigger.current.contains(target as Node)) return;

      setMobileNavOpen(false)
    };
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }): void => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false)
    };
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })
  //  alert(mobileNavOpen)
  return (
    <div className="md:hidden">
      {/* Hamburger button */}
      <button
        ref={trigger}
        className={`hamburger ${mobileNavOpen && 'active'}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <span className="sr-only">Menu</span>
        <svg
          className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="4" width="24" height="2" rx="1" />
          <rect y="11" width="24" height="2" rx="1" />
          <rect y="18" width="24" height="2" rx="1" />
        </svg>
      </button>

      {/*Mobile navigation */}
      <nav
        id="mobile-nav"
        ref={mobileNav}
        className="absolute left-0 z-20 w-full px-4 overflow-hidden transition-all duration-300 ease-in-out top-full sm:px-6"
        style={mobileNavOpen ? { maxHeight: mobileNav.current?.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: 0.8 }}
      >
        <ul className="px-4 py-2 bg-gray-200">
          <Link
            to=""
            className="inline-flex items-center justify-center w-full py-2 px-2 font-medium text-green-500 bg-white rounded hover:bg-green-100 transition duration-300" onClick={() => setMobileNavOpen(false)}
          >
            Find a Coche
          </Link>
          <Link
            to=""
            className="inline-flex items-center justify-center w-full py-2 px-2 font-medium text-green-500 bg-white rounded hover:bg-green-100 transition duration-300" onClick={() => setMobileNavOpen(false)}
          >
            Group Class
          </Link>
          <Link
            to=""
            className="inline-flex items-center justify-center w-full py-2 px-2 font-medium text-green-500 bg-white rounded hover:bg-green-100 transition duration-300" onClick={() => setMobileNavOpen(false)}
          >
            Become a Teacher
          </Link>
          <li>
            <Link to="/signin" className="flex justify-center w-full py-2 font-medium text-green-500 hover:text-gray-500" onClick={() => setMobileNavOpen(false)}>
              Sign in
            </Link>
          </li>
          <li>
            <Link
              to="/signup"
              className="inline-flex items-center justify-center w-full py-2 px-2 font-medium text-white bg-green-500 rounded hover:bg-green-400 transition duration-300" onClick={() => setMobileNavOpen(false)}
            >
              Sign up
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
