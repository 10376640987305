import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';

import FindIntro from './Content/FindIntro';
import ShowCoach from './Content/ShowCoach';
import Offer from './Content/Offer';
import Feedback from "./Content/Feedback";
import Question from './Content/Question';
import UseDevice from './Content/UseDevice';
import QuickAccess from './Content/QuickAccess';
import { styled } from 'styled-components';
import { contentStyle } from '../fuctions';

import { SearchOutlined } from '@ant-design/icons';
import SearchInput from '../components/SearchInput';
const { Header, Content, Footer } = Layout;
const AContent = () => {
    const user = false;
    return <Content>
        {!user ?
            <>
                {/* <Intro></Intro> */}
            </> :
            <Button>33sss</Button>}
        <FindIntro></FindIntro>
        <SearchInput></SearchInput>
        <ShowCoach></ShowCoach>

        <Question></Question>
        <QuickAccess></QuickAccess>
    </Content>;
};

export default AContent;
