import Wrapper from '../../assets/wrappers/Intro';
import Hello from '../../components/Hello';
import Picture from '../../components/Picture';
import { contentStyle } from '../../fuctions';


const FindIntro = () => {
  return (
    <Wrapper>
      <div className="max-w-7xl px-4 mx-auto sm:px-6    ">
        <div className="relative pt-32 pb-10 md:pt-40 md:pb-16">
          <div style={contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 35px;
line-height: 42px;

color: #000000;
`}>Find the best coach for you now</div>
          <div style={contentStyle`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;

color: #5C5C5C;
`}>Looking for a great way to improve your self? coach provides you with qualified coaches. Hire an online coach to help you in training.</div>
        </div>
      </div>

    </Wrapper>
  );
};

export default FindIntro;
