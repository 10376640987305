import { contentStyle } from "../fuctions";


const linkList = [
    {
        type: "Lesson coaches",
        items: [
            "Fitness coach",
            "Wellness coach",
            "Business coach",
            "Personal development coach",
            "Cooking coach",
            "Mind set coaches",
            "Language coaches",
            "Communication coaches",
            "Public speaking coaches",
            "Sports coach",
        ]
    },
    {
        type: "Train in",
        items: [
            "Fitness coach",
            "Wellness coach",
            "Business coach",
            "Personal development coach",
            "Cooking coach",
            "Mind set coaches",
            "Language coaches",
            "Communication coaches",
            "Public speaking coaches",
            "Sports coach",
        ]
    },
    {
        type: "Modes of Training",
        items: [
            "1 on 1 training",
            "Group classes"
        ]
    }
];

const typeStyle = contentStyle`
width: 25%;
text-align: left;
margin: 20px;
`;

const typeTitleStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 38px;
/* identical to box height */


color: #000000;
`;

const linkStyle = contentStyle`
// margin-top: 3%;

font-family: 'Outfit';
font-style: normal;
font-weight: 300;
// font-size: 25px;
line-height: 25px;
/* identical to box height */


color: #000000;
`;

const Links = () => {
    return <>
        {linkList.map((i, index) => {
            return <div key={index} style={typeStyle}>
                <div style={typeTitleStyle}>{i.type}</div>
                {i.items.map((j, jIndex) => {
                    return <div key={index + jIndex} style={linkStyle}>
                        {j}
                    </div>;
                })}
            </div>
                ;
        })}</>;
};

export default Links;
