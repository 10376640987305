import Wrapper from '../../assets/wrappers/FeedbackCarouselWrap';
import FeedbackSectionTitle from '../../components/FeedbackSectionTitle';
import FeedbackCarousel from '../../components/FeedbackCarousel';
import { contentStyle } from '../../fuctions';


const Feedback = () => {
  return (
    <Wrapper>
      <div className="max-w-7xl px-4 mx-auto sm:px-6">
        <div style={contentStyle`    display: flex;
    gap: 20px;
    flex-direction: column;`}
        >
        <FeedbackSectionTitle></FeedbackSectionTitle>
        <FeedbackCarousel></FeedbackCarousel>
        </div>
        </div>
    </Wrapper>
  );
};

export default Feedback;
