import React from 'react';
import { Carousel, } from 'antd';
import styled from 'styled-components';
import SignUpForm1 from './SignUpForm1';
import SignUpForm2 from './SignUpForm2';
import SignUpForm3 from './SignUpForm3';

const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    display: none;
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    display: none;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: red;
  }
`;

const props = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const StepSetting = (props) => {
  let carousel = React.createRef ();
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const next =()=>{
    console.log(carousel);
    ////////////////////Call Funcions From Props/////////////////////////
    props.goToStep();
    /////////////////////////////////////////////////////////////////////
    carousel.next();

  }
  
  return (
    <CarouselWrapper ref={node =>(carousel=node)} {...props}>
      <div>
        <SignUpForm1 nextClick={next} />
      </div>
      <div>
        <SignUpForm2 nextClick={next} />
      </div>
      <div>
        <SignUpForm3 nextClick={next} />
      </div>
    </CarouselWrapper>

  );
};

export default StepSetting;