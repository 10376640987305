import { Button } from "antd";
import { contentStyle } from "../fuctions";

import { GithubFilled, InstagramFilled, FacebookFilled, TwitterCircleFilled} from '@ant-design/icons';

const externStyle = contentStyle`
display: inline-flex;
align-items: center;

width: 296px;
height: 30px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 30px;

color: #000000;

cursor: pointer;

`;

const Extern = () => {
    return <div style={externStyle}>
        <GithubFilled></GithubFilled>
        <InstagramFilled></InstagramFilled>
        <FacebookFilled></FacebookFilled>
        <TwitterCircleFilled></TwitterCircleFilled>
</div>;
  };
  
  export default Extern;
  