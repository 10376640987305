import React from 'react';
import Wrapper from '../../assets/wrappers/ShowGroupCoachWrap';
import ShowGroupCoachCards from './../../components/ShowGroupCoachCards';
import AspectTypeList from '../../components/AspectTypeList';
const ShowGroupClassCoach = () => {
  const [currentAspect, setAspect] = React.useState(-1);

  const selectAspect = (i) => {
    setAspect(i);
  }

  return (
    <Wrapper>
      <div 
      // className="max-w-7xl px-4 mx-auto sm:px-6"
      >
        <div 
        style={{display:"flex", flexDirection:"column", gap:20}}
        >
          <AspectTypeList></AspectTypeList>
          <ShowGroupCoachCards></ShowGroupCoachCards>
          
        </div>
      </div>
    </Wrapper>
  );
};

export default ShowGroupClassCoach;