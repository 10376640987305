import { ProjectOutlined,GroupOutlined,ClockCircleFilled, AppstoreOutlined, MailOutlined, SettingOutlined ,SearchOutlined} from '@ant-design/icons';
 
import lessonImage from "./../assets/images/lesson.png";
import groupImage from "./../assets/images/group.png";
import chatImage from "./../assets/images/chat.png";
// export const landingNavLinks = [{

// },{

// }];
export const landingNavLinks = [
    {
      label: 'Find a Coach',
      key: '1',
      icon: <SearchOutlined />,
    },
    {
      label: 'Group Class',
      key: '2',
      icon: <GroupOutlined />,
    },
    {
      label: 'Become a teacher',
      key: '3 ',
      icon: <ProjectOutlined />,
    },
    // {
    //   label: 'Navigation Two',
    //   key: 'app',
    //   icon: <AppstoreOutlined />,
    //   disabled: true,
    // },
    // {
    //   label: 'Navigation Three - Submenu',
    //   key: 'SubMenu',
    //   icon: <SettingOutlined />,
    //   children: [
    //     {
    //       type: 'group',
    //       label: 'Item 1',
    //       children: [
    //         {
    //           label: 'Option 1',
    //           key: 'setting:1',
    //         },
    //         {
    //           label: 'Option 2',
    //           key: 'setting:2',
    //         },
    //       ],
    //     },
    //     {
    //       type: 'group',
    //       label: 'Item 2',
    //       children: [
    //         {
    //           label: 'Option 3',
    //           key: 'setting:3',
    //         },
    //         {
    //           label: 'Option 4',
    //           key: 'setting:4',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   label: (
    //     <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
    //       Navigation Four - Link
    //     </a>
    //   ),
    //   key: 'alipay',
    // },
  ];
export const  homeNavLinks = [{

}];

export const aspectList = [
  {
    type: <span className="inline-flex items-baseline"><SearchOutlined/> Lesson Category</span>,
    value:[
      "Wellness", "Business", "lose weight", "Gain weight","..."
    ]
  },
  {
    type: <span className="inline-flex items-baseline"><ClockCircleFilled/> Lesson Time</span>,
    value:[
      "Wellness", "Business", "lose weight", "Gain weight","..."
    ]
  },
  {
    type: <>Speaks</>,
    value:[
      "Wellness", "Business", "lose weight", "Gain weight","..."
    ]
  },
  {
    type: <>More</>,
    value:[
      "Wellness", "Business", "lose weight", "Gain weight","..."
    ]
  },
  {
    type: <>Price</>,
    value:[
      "Wellness", "Business", "lose weight", "Gain weight","..."
    ]
  },
  {
    type: <>GigLanguage</>,
    value:[
      "Wellness", "Business", "lose weight", "Gain weight","..."
    ]
  }
];

export const coachList = [
  {
    
  },
  {
    
  },
  {
    
  },
  {
    
  },
  {
    
  },
  {
    
  },
  {
    
  },
  {
    
  },
  {
    
  },
  {
    
  },
]



export const coachTypeList = [
  {
    image:lessonImage,
    title: "1-on-1 lessons",
    description: "Find coaches from all over the world sharing their skills, knowledge, and cultures.",
    goTo:"Find my Coach",
  },
  {
    image:groupImage,
    title: "Group Class",
    description: "Fun and engaging online group classes designed and led by expert coaches.",
    goTo:"View all classes",
  },
  {
    image:chatImage,
    title: "Chat communication",
    description: "Conversations and chats between individuals is possible through provided fields.",
    goTo:"Make connection",
  },
];

export const feedbackList = [
  {
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  },{
    rate:5,
    verified: true,
    title: "Every thing works very well",
    description: "Everything works faster and makes everything easy.",
    name: "John Doe",
    postDate: "20 hours ago"
  }
]