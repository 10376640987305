import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';

import Intro from './Content/Intro';
import ShowCoach from './Content/ShowCoach';
import Offer from './Content/Offer';
import Feedback from "./Content/Feedback";
import Question from './Content/Question';
import UseDevice from './Content/UseDevice';
import QuickAccess from './Content/QuickAccess';

const { Header, Content, Footer } = Layout;

const AContent = () => {
  const user = false;
  return <Content>
    {!user ?
      <>
        <Intro></Intro>
      </> :
      <Button>33sss</Button>}
    <ShowCoach></ShowCoach>
    <Offer></Offer>
    <Feedback></Feedback>
    <Question></Question>
    <UseDevice></UseDevice>
<QuickAccess></QuickAccess>
  </Content>;
};

export default AContent;
