

import { Button } from "antd";
import { contentStyle } from "../fuctions";
import reviewAvatar from "./../assets/images/reviewAvatar.png"
const sectionTitleStyle = contentStyle`
text-align: left;

font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 32px;
/* identical to box height */


color: #000000;
`
const cardStyle = contentStyle`
// position: absolute;
width: 300px;
// height: 300px;
// left: 200px;
// top: 2837px;

background: #FFFFFF;
box-shadow: 0px 4px 20px 5px rgba(190, 190, 190, 0.25);
border-radius: 20px;
`;

const imageStyle = contentStyle`
width: 50px;
height: 50px;
`;
const nameStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 32px;
/* identical to box height */


color: #000000;
`;
const lessonStyle = contentStyle`

font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 19px;
/* identical to box height */


color: #8F8F8F;
`;

const descriptionStyle = contentStyle`
text-align: justify;
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
font-size: 15px;
line-height: 19px;

color: #000000;

`;

const timeStyle = contentStyle`
font-family: 'Outfit';
font-style: normal;
font-weight: 300;
font-size: 15px;
line-height: 19px;

color: #000000;
`;

const Reviews = () => {

    const items = [
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        },
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        },
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        },
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        },
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        },
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        },
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        },
        {
            avatar: reviewAvatar,
            name: "Emmy",
            lessons: "8 lessons",
            description: "Today’s class was fantastic and entertaining like usual! Claire can always find some interesting and creative materials for my 4 years ago daughter to learn French. Claire even provided rather useful homework accordingly！She is the best!!",
            time: "Aug 11, 2022"
        }
    ];

    const showMoreButtonStyle = contentStyle`
    box-sizing: border-box;

// position: absolute;
// width: 250px;
// height: 70px;
// left: 504px;
// top: 3546px;

background: #FFFFFF;
border: 1px solid #DADADA;
border-radius: 10px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
// font-size: 25px;
// line-height: 30px;

color: #000000;

    `;


    return <>
        <div className="p-5 flex inline-flex flex-col gap-5">
            <div style={sectionTitleStyle}>Reviews</div>
            <div
                style={{
                    // width: 1387
                }}
                className={`
        w-full 
        flex
    inline-flex 
    items-center 
    flex-wrap
    justify-center
    gap-[30px] 
        `}
            >
                {items.map((i, index) => {
                    return <div key={index} className="p-5 flex inline-flex flex-col gap-5" style={cardStyle}>
                        <div className="flex inline-flex gap-3">
                            <img src={i.avatar} style={imageStyle} />
                            <div>
                                <div style={nameStyle}>{i.name}</div>
                                <div style={lessonStyle}>{i.lessons}</div>
                            </div>
                        </div>
                        <div style={descriptionStyle}>
                            {i.description}
                        </div>
                        <div style={timeStyle}>{i.time}</div>

                    </div>
                })}
            </div>
            <div className="flex justify-center">
                <Button style={showMoreButtonStyle}>
                    Show More
                </Button>
            </div>
        </div>
    </>;
};

export default Reviews;
